import { useState, useMemo, useRef, useEffect  } from 'react'

import { Color } from 'three'

import {GetRandColor} from './helpers/ColorHelpers'
import SearchBox from './helpers/SearchBox'

import defaultTagOptions from './constants/defaultTagOptions'
import {TikTokCatsOrder} from './DiamondsGrid'

import { PhoneDayCounters, ActiveVisuals, EDTR_ACTIVE_DATA, SetEdtrActiveData } from  './helpers/VisualsDataExchange'
import { 
	DATA_OPTIONS, 
	VISUAL_OPTIONS, 
	VISUAL_OPTION_SHAPES, 
	VisHasOptionShape, 
	VisHasOptionShapeAndCurShape,
	VisHasOptionSpeed, 
	VisHasVisualOptions, 
	VisHasGridOptions 
} from  './constants/DataVisSettings'
import { shapeCounter } from './Layouter'
// User Logger
import { UserLog } from  './UserActivityLogger'


// import hashtagOptions from "./data/hashtags.json";
// import creatorOptions from "data/creator.json";

const colorRGBVals = []

function ColorOptions({callBack, visualData, numOptions, colorOptions}) {

	const [curVisualData, setCurVisualData] = useState(visualData)
	const [curActiveColor, setCurActiveColor] = useState(visualData.displayColor)

	useEffect(() => {
		setCurVisualData(visualData)
		setCurActiveColor(visualData.displayColor)
	}, [visualData])


	if(!numOptions) { numOptions = 8; }

	if(!colorOptions) {
		colorOptions = []
		for(let i=0;i<numOptions;i++) {
			colorOptions.push( GetRandColor() )
		}
	}

	const [curColorOptions, setCurColorOptions] = useState(colorOptions)

	const SwatchClick = (cSwatch) => {
		// activeColor = cSwatch;
		setCurActiveColor(cSwatch)
		curVisualData.displayColor = cSwatch

		// Log it
		UserLog({ColorSwatchClick:cSwatch})

		callBack(curVisualData)
	}


	// Get the options for the swatches
	const getSwatchOptions = (reset) => {
		let num2Get = numOptions;
		let colorSwatches = []


		let all_new = (reset) ? true : false;
		if(!all_new) {
			// Copy current
			colorSwatches = [...curColorOptions];
			let numAvail = colorSwatches.length;
			num2Get = numOptions - numAvail;
		}
		if(num2Get > 0) {
			for(let i=0;i<num2Get;i++) {
				let randColor = GetRandColor()
				colorSwatches.push( randColor )
				// generatedColorOptions
			}
		}
		// Reset color vals & populate, so can check if active color in there
		colorRGBVals.length = 0
		for(let clr of colorSwatches) {
			let clrTotal =  clr.r + ',' + clr.g + ',' + clr.b
			colorRGBVals.push( clrTotal  )
		}

		return colorSwatches;
	}
	// Get the swatch buttons
	let swatchBtns = []

	const ColorSwatches = () => {
		swatchBtns = []
		// console.log(colorRGBVals, curActiveColor)
		// If there's an active color
		if(curActiveColor) {
			// Check if its in the current options
			let compVal = curActiveColor.r + ',' + curActiveColor.g + ',' + curActiveColor.b
			if(colorRGBVals.indexOf(compVal) == -1) {
				// If not set as the first option
				curColorOptions[0] = curActiveColor
			}
		}


		// let swatches2Display = getSwatchOptions(reset)
		let option_i = 0;
		for(let cSwatch of curColorOptions) {
			// BG color
			const bgColorStyle = { backgroundColor: '#' + cSwatch.getHexString() }
			// Class
			let btnClassName = 'change-color';
			if(curActiveColor == cSwatch ) { btnClassName+= ' active';	}
			// Set element
			const optionLi = <li key={`color-op-${option_i}`}>
				<a 
					onClick={ (e) => { SwatchClick(cSwatch) } } 
					className={btnClassName}
				>
					<i style={bgColorStyle}></i>
				</a>
			</li>
			// Add to options
			swatchBtns.push(optionLi)

			option_i++
		}
		return swatchBtns;
	}
	useEffect(() => {
		initColorSet()
	}, [visualData.displayColor])

	function initColorSet() {
		let newColors = getSwatchOptions( false )
		setCurColorOptions( newColors )
	}

	const newColorSet = (e) => {
		UserLog({ColorOptions:'generated_new_options'})

		let newColors = getSwatchOptions( true )
		setCurColorOptions(newColors)
	}

	return 	<>
		<a id="get-new-color-ops" onClick={newColorSet}>generate new options</a>
		<ul>
			<ColorSwatches />
		</ul>
	</>
}

const searchOpsLoaded = {
	creator: false,
	hashtag: false
}

export function TagOptionsTab({dataType, visualData, callBack}) {

	let dataTypes = dataType + 's';

	const [tagOptions, setTagOptions] = useState( defaultTagOptions[dataTypes] )
	const [activeTag, setActiveTag] = useState( (visualData && visualData.hasOwnProperty('dataTag')) ? visualData.dataTag : '')

	const [dataOptions, setDataOptions] = useState([])

	const loadSearchOptions = async (fileName) => {
		const response = await fetch(`data/${fileName}.json`)
		const result = await response.json()
		dataOptionsLoaded(result)
		
	}
	const dataOptionsLoaded = (data) => {
		searchOpsLoaded[dataType] = data
		setDataOptions(data)
	}
	// Inital load, get the options from json file
	useEffect(()=>{
		if(searchOpsLoaded[dataType] == false) {
			searchOpsLoaded[dataType] = 'loading'
			console.log('loadSearchOptions', dataType)
			// searchOpsLoaded[dataType] = true
			loadSearchOptions(dataTypes)
		} else {
			setDataOptions( searchOpsLoaded[dataType] )
		}
	},[])

	useEffect(() => {
		if(visualData && visualData.hasOwnProperty('dataTag')) {
			setActiveTag(visualData.dataTag)
		}
	}, [visualData])


	const getRandOption = (event) => {
		UserLog({TagOptionsTab:'getRandOption'})

		let randTag = dataOptions[Math.floor(Math.random()*dataOptions.length)];
		// console.log('randTag', randTag)
		addToTagOps({name:randTag})
	}

	const addToTagOps = (item) => {
		const initOps =  [...tagOptions]
		if(initOps.indexOf(item.name) == -1) {
			initOps.push(item.name)
			setTagOptions(initOps)
			setSelectedTag(item.name)
		}
	}
	const setSelectedTag = (item) =>  {
		setActiveTag(item)
		// Log it
		UserLog({TagOptionsTab:'setSelectedTag:'+item})		
		callBack({dataType: dataType, dataTag: item })
	}


	// Create button array
	const tagBtns = [];

	// Add random button
	let btn_txt = 'random ' + dataType;
	tagBtns.push( <a key={`tag-random`} className={`rand-btn set-${dataType}`} onClick={getRandOption}>{btn_txt}</a> )

	// Add prev added random tag to options
	if(
		visualData
		&&
		visualData.dataTag
		&&
		visualData.dataType == dataType
		&&
		visualData.dataTag!='*' 
		&& 
		tagOptions.indexOf(visualData.dataTag) == -1
	) { 
		tagOptions.push(visualData.dataTag)
	}

	// Add cur options
	for(let tag of tagOptions) {
		let className = `set-${dataType}`
		if(tag == activeTag) {  className+= ' active' }
		tagBtns.push( <a 
				key={`tag-${tag}`} 
				className={className} 
				data-tag={tag} 
				onClick={ () => setSelectedTag(tag)}
			>{tag}</a> 
		)
	}

	return (
		<>
		{dataOptions.length &&
			<div className="tag-ops-wrap">
				<SearchBox optionsArray={dataOptions} callBack={addToTagOps} />
				<div className="ops-wrap">
					{tagBtns}
				</div>
			</div>			
		}
		</>
	)
}

function CategoriesSelector({dataType, visualData, callBack}) {

	// console.log('CategoriesSelector!', visualData)

	// 


	const [activeCats, setActiveCats] = useState([])

	useEffect(() => {
		let curActive = TikTokCatsOrder;
		if(visualData.hasOwnProperty('activeCats')) {
			if(visualData.activeCats != '*') {
				curActive = visualData.activeCats.split(',')
			}
		}
		setActiveCats(curActive)
	}, [visualData])

	const updateActiveCats = (event) => {

		const updateCurActive = [...activeCats]
		const cbChecked = event.target.checked
		const cbVal = event.target.value
		// console.log('cb ', cbChecked)
		if(!cbChecked) {
			const valI = updateCurActive.indexOf(cbVal)
			const xd = updateCurActive.splice(valI, 1);
			// delete updateCurActive[valI]
		} else {
			updateCurActive.push(cbVal)
		}
		setActiveCats(updateCurActive)
		const callBackTagVal = (updateCurActive.length == TikTokCatsOrder.length) ? '*' : updateCurActive.join(',')
		// Log it
		UserLog({updateActiveCats:callBackTagVal})

		callBack({dataType: dataType, dataTag: '*', activeCats: callBackTagVal })
	}

	const catsCheckBoxList = []
	TikTokCatsOrder.map(function(catName, index){
		const isChecked = (activeCats.indexOf(catName) != -1) ? true : false
		catsCheckBoxList.push(<li key={`cat-op-${catName}`}>
			<label>
				<input onChange={updateActiveCats} type="checkbox" name="active-categories" value={catName} checked={isChecked} />
				{catName}
			</label>
		</li>)
	})



	return <section className="categories-select-wrap">
			<ul>{catsCheckBoxList}</ul>
	</section>	
}

function EditorDataTabs({callBack, visualData}) {

	const [activeTab, setActiveTab] = useState( visualData.dataType )
	const [curVisualData, setCurVisualData] = useState(visualData)


	useEffect(() => {
		setActiveTab(visualData.dataType)
		setCurVisualData(visualData)
	}, [visualData])


	const SwapTab = (event) => {
		let newActive = event.target.dataset.mode;
		// Log it
		UserLog({SwappedTab:newActive})

		let updateSettings = false;
		if('categories' == newActive && 'categories' != curVisualData.dataType) {
			curVisualData.dataTag = '*'
			updateSettings = true;
			curVisualData.visType = 'DiamondsGrid'
			curVisualData.shapeType = 'DiamondsGridMini'
			// delete curVisualData.visType
			delete curVisualData.displayColor		
		// Not the initial data type? Clear the tag			
		} else if(curVisualData.hasOwnProperty('dataType') && newActive != curVisualData.dataType ) {			
			delete curVisualData.dataTag
			delete curVisualData.visType
			delete curVisualData.displayColor

			updateSettings = true;
		}
		if(updateSettings) { 
			curVisualData.dataType = newActive
			updateDataSettings(curVisualData) 
		}
		setActiveTab(newActive)
	}

	const updateDataSettings = (dataSettings) => {
		const newVisData = { ...curVisualData, ...dataSettings }
		// setCurVisualData( newVisData )
		// console.log('updateDataSettings', newVisData)
		callBack(newVisData)
		// JSON.parse(JSON.stringify(curVisualData));
	}

	const TabHeader = () => {
		
		let headerBtns = []

		for(let tabKey in DATA_OPTIONS) {
			let tabData = DATA_OPTIONS[tabKey];
			let activeClass = ( tabData.variations.indexOf(activeTab) != -1  ) ? 'active' : '';
			const tabBTN = <li key={`tab-${tabKey}`}><a onClick={SwapTab} className={activeClass} rel={`tab-${tabKey}`} data-mode={tabKey}>{tabData.name}</a></li>
			headerBtns.push(tabBTN)
		}

		return 	<header>
			<ul>{headerBtns}</ul>
		</header>
	}

	const tabsClass = 'tab';


	return <div className="tab-group">
		<TabHeader />
		{/* Hashtags */}
		<div className={tabsClass + (DATA_OPTIONS['hashtag'].variations.indexOf(activeTab) != -1 ? ' active' : '')} id="tab-hashtag">
			<TagOptionsTab dataType="hashtag" visualData={curVisualData} callBack={updateDataSettings}  />
		</div>
		{/* Creator */}
		<div className={tabsClass + (DATA_OPTIONS['creator'].variations.indexOf(activeTab) != -1 ? ' active' : '')} id="tab-creator">
			<TagOptionsTab dataType="creator" visualData={curVisualData} callBack={updateDataSettings}  />
		</div>
		{/* Categorie */}
		<div className={tabsClass + (DATA_OPTIONS['categories'].variations.indexOf(activeTab) != -1 ? ' active' : '')} id="tab-categorie">
			<div className="categories-ops-wrap ops-wrap">
				<CategoriesSelector dataType="categories" visualData={curVisualData} callBack={updateDataSettings} />
			</div>
		</div>
	</div>
}

function VisualTypesSelect({callBack, visualData}) {

	const [curVisualData, setCurVisualData] = useState(visualData)
	const [curType, setCurType]             = useState(visualData.visType)
	const [curShape, setCurShape]           = useState(visualData.shapeType)

	// console.log('VisualTypesSelect!', visualData, curVisualData)

	useEffect(() => {
		setCurVisualData(visualData)
		setCurType(visualData.visType)
		setCurShape(visualData.shapeType)
	}, [visualData])


	// console.log('visualData', visualData)
	const availableOptions = DATA_OPTIONS[visualData.dataType].visual_options

	const setVisOption = (event) => {
		const visType = event.target.dataset.vis
		if(curVisualData.visType != visType) {
			setCurType( visType )
			// Set new prop to visualdata
			curVisualData.visType = visType
			// Remove some props from visualdata
			// delete curVisualData.shapeType
			// delete curVisualData.displayColor
			// setCurShape('')
			// console.log('setVisOption', visualData)
			// Log it
			UserLog({setVisOption:visType})
			// Update the caller
			callBack(curVisualData)
		}
	}

	const setShapeOption = (event) => {
		const shapeType = event.target.dataset.shape
		// console.log('shapeType!', shapeType)
		if(curVisualData.shapeType != shapeType) {
			setCurShape( shapeType )
			curVisualData.shapeType = shapeType
			// Log it
			UserLog({setShapeOption:shapeType})
			callBack(curVisualData)
		}
	}

	const VisualOptionsList = () => {
		
		let visOpBtns = []

		for(let visOp of availableOptions) {			
			let activeClass = ( curType == visOp  ) ? 'active' : '';
			// Is not active (as in currently being edited)
			let addOption = true
			if('' == activeClass ) {
				if(VISUAL_OPTIONS[visOp].hasOwnProperty('max')) {
					const maxInstances = VISUAL_OPTIONS[visOp].max
					const shape2Check =  VISUAL_OPTIONS[visOp].shape
					if(shapeCounter[shape2Check] >= maxInstances) {
						addOption = false
					}
				}

			}
			if(addOption) {
				const tabBTN = <li key={`visual-option-${visOp}`}><a onClick={setVisOption} className={activeClass}  data-vis={visOp}></a></li>
				visOpBtns.push(tabBTN)
			}
		}

		return 	<ul className={`visual-options num-${availableOptions.length}`}>{visOpBtns}</ul>
	}

	const ShapeOptionsList = () => {
		if(VisHasOptionShape(curType)) {
			let shapeOpBtns = []
			for(let shapeOp of VISUAL_OPTION_SHAPES[curType]) {
				let activeClass = ( curShape == shapeOp  ) ? 'active' : '';
				const tabBTN = <li key={`visual-option-${shapeOp}`}><a onClick={setShapeOption} className={activeClass}  data-shape={shapeOp}></a></li>
				shapeOpBtns.push(tabBTN)
			}

			return 	<div id="visual-shape-ops-wrap">
				<h3>Select a shape</h3>
				<ul className={`shape-options num-${availableOptions.length}`}>{shapeOpBtns}</ul>
			</div>
		}
	}

	return <div id="visual-types-wrap">
		<h3>Visual type</h3>
		<VisualOptionsList />
		<ShapeOptionsList />
	</div>
}
function GridSizeSelect({callBack, visualData}) {
	const [curVisualData, setCurVisualData] = useState(visualData)
	const [curType, setGridSize]             = useState(visualData.shapeType)

	useEffect(() => {
		setCurVisualData(visualData)
		setGridSize(visualData.shapeType)
	}, [visualData])


	const availableOptions = DATA_OPTIONS[visualData.dataType].grid_options
	const numOptions       = Object.keys(availableOptions).length

	const setGridOption = (event) => {
		curVisualData.visType = 'DiamondsGrid'
		const gridSize = event.target.dataset.grid
		if(curVisualData.shapeType != gridSize) {
			setGridSize( gridSize )
			// Set new prop to visualdata
			curVisualData.shapeType = gridSize
			// Remove some props from visualdata
			// delete curVisualData.displayColor
			// Log it
			UserLog({GridSizeSelect:gridSize})
			// Update the caller
			callBack(curVisualData)
		}
	}

	const GridOptionsList = () => {
		
		let visOpBtns = []

		for(let gridSize in availableOptions) {
			let visOp = availableOptions[gridSize]
			let activeClass = ( curType == visOp  ) ? 'active' : '';
			const tabBTN = <li key={`grid-option-${visOp}`}><a onClick={setGridOption} className={activeClass}  data-grid={visOp}>{gridSize}</a></li>
			visOpBtns.push(tabBTN)
		}

		return 	<ul className={`grid-options num-${numOptions}`}>{visOpBtns}</ul>
	}

	return <div id="grid-types-wrap">
		<h3>Grid Size</h3>
		<GridOptionsList />
	</div>

}

function EditorSubmitActive(visualData) {
	let btnActive = false
	console.log('EditorSubmitActive?', visualData)
	// categories? color set: yes
	if(
		visualData.displayColor
	) {
		btnActive = true
	}
	if(
		visualData.hasOwnProperty('visType')
		&&
		VisHasOptionShape( visualData.visType )
	) {
		let VisShapeSet = VisHasOptionShapeAndCurShape(visualData.visType,visualData.shapeType)
		if(VisShapeSet && visualData.displayColor) {
			btnActive = true	
		}
		
	}
	// 
	return btnActive
}
function EditorStepReady(step, visualData) {
	let ready = false
	// console.log('EditorStepReady?', step, visualData)
	switch(step) {
		case 'color':
			if('categories' == visualData.dataType && visualData.visType) {
				ready = true
			} else if(
				visualData.visType
				&&
				(
					visualData.shapeType
					||
					!VisHasOptionShape(visualData.visType)
				)
			) {
				ready = true
				if(!VisHasOptionShapeAndCurShape(visualData.visType, visualData.shapeType)) {
					ready = false
				}
			}
		  break;
		case 'speed':
			if(visualData.hasOwnProperty('displayColor')) { ready = true }
		  break;
	}
	return ready
}

export default function PhoneEditor({visualIndex, visualData, callBack, editorMode}) {
	
	// const [displayClass, setDisplayClass] =  useState('')
	const [curVisualData, setCurVisualData] = useState( {} )
	const [editI, setEditI] = useState(0)

	// don't update unnecessarily
	// if ('edit' == editorMode && curVisualData !== visualData) { setCurVisualData(visualData);  }
	useEffect(() => {
		// console.log('PhoneEditor:useEffect! visualData has changed ', visualData)
		if('add' == editorMode ) { visualData = {} }
		setCurVisualData(visualData)
	}, [visualData, editorMode])

	const titlePrefix = (editorMode == 'edit') ? 'Edit' : 'Add'

	let displayClass = '';
	if(editorMode == 'edit' || editorMode == 'add') {
		displayClass = 'shown';
	}

	const closeBtn = useRef();

	const closeEditor = (event) => {
		visualIndex = -1
		displayClass = '';
		callBack(event)
		// setDisplayClass('')
	}

	const defaultPlaybackSpeed = (curVisualData && curVisualData.hasOwnProperty('displaySpeed')) ? curVisualData.displaySpeed : 10

	const setPlayBackSpeed = (event) => {
		curVisualData.displaySpeed = parseInt( event.target.value )
		setCurVisualData(curVisualData)
	}

	const changePhoneSettings = (dataSettings, extendData = false) => {
		setCurVisualData(dataSettings)
		setEditI( editI + 1)
	}
	// Has visual options? (alleen categories niet)
	let hasVisualOptions = VisHasVisualOptions(curVisualData)
	let hasGridOptions   = VisHasGridOptions(curVisualData)
	// Speed control ?
	const hasSpeedControl = VisHasOptionSpeed(curVisualData.visType)
	// Show color options ?
	let stepReadyColor = (editorMode == 'edit') ? true : EditorStepReady('color', curVisualData)
	// Show speed option ?
	let stepReadySpeed = (editorMode == 'edit') ? true : EditorStepReady('speed', curVisualData)
	// Show Submit ?
	let submitDisplay = EditorSubmitActive(curVisualData)

	// Commit
	const commitVisual = () => {
		// console.log(editorMode, 'commitVisual!', curVisualData)
		SetEdtrActiveData(curVisualData)
		// Log it
		UserLog({CommitVisual:editorMode})
		callBack({editorMode, curVisualData})
		// Clear prev relevant data
		setCurVisualData({})
		editorMode = ''
	}
	const labelSubmit = (titlePrefix == 'Edit') ? 'Apply Changes' : 'Add Visual'


	return <div id="editorOutput" className={displayClass}>
			<header>
				<h1>{titlePrefix} Visual</h1>
			</header>
			<section id="data-input">
				<h2>Choose data input</h2>
				{/* Edit DATA_INPUT */}
				<EditorDataTabs callBack={changePhoneSettings} visualData={curVisualData} />
			</section>
			{/* dataTag Chosen ? Proceed to step 2 */}
			{curVisualData.hasOwnProperty('dataTag') &&			
				<section id="data-display">
					<h2>Choose visual output</h2>
					{hasVisualOptions &&
						<VisualTypesSelect callBack={changePhoneSettings} visualData={curVisualData} />
					}
					{hasGridOptions &&
						<GridSizeSelect callBack={changePhoneSettings} visualData={curVisualData} />					
					}
					{/* Edit COLOR */}
					{stepReadyColor && 
					<div id="color-wrap">
						<h3><i></i> Color</h3>
						<ColorOptions visualData={curVisualData} callBack={changePhoneSettings}  numOptions={9} />
					</div>
					}
					{/* Edit SPEED */}
					{hasSpeedControl && stepReadySpeed &&
					<div id="speed-wrap">
						<h3><i></i> Speed</h3>
						<input onChange={setPlayBackSpeed} type="range" id="playBackSpeed" min="1" max="20" defaultValue={defaultPlaybackSpeed} step="1" />
					</div>
					}
				</section>
			}
			{submitDisplay &&
				<button id="submit-data-visual" onClick={commitVisual}>{labelSubmit}</button>
			}
		</div>
}
