export const DATA_OPTIONS = {
	hashtag: {
		name: 'hashtag',
		visual_options: [
			'DayBoxes',
			'DayHeightMap',
			'BigSingleShape',			
			'DiamondsRing',
		],
		variations: ['hashtag100', 'hashtag']
	},
	creator: {
		name: 'creator',
		visual_options: [
			'CreatorHeightmap',
			// 'DayHeightMap',
			'BigSingleShape',
			'DiamondsRing'		
		],
		variations: ['creator100', 'creator', 'creatorgridindexed', 'creatormulti']
	},
	categories: {
		name: 'categories',
		grid_options: {
			100: 'DiamondsGridMini',
			10000: 'DiamondsGrid',
		},
		variations: ['categories', 'categorie', 'categories1000']
	}
}
const MAX_SQUARES = 6
export const VISUAL_OPTIONS = {
	DayHeightMap: {
		animated: true,
		shape: 'square',
		max: MAX_SQUARES
	},
	CreatorHeightmap: {
		animated: true,
		shape: 'square',
		max: MAX_SQUARES
	},
	DayBoxes: {
		animated: true,
		shape: 'square',
		max: MAX_SQUARES
	},
	DiamondsRing: {
		animated: false,
		shape: 'outside',
		max: 1
	},
	BigSingleShape: {
		animated: true,
		shape: 'single',
		max: 2
	},
	DiamondsGrid: {
		animated: true,
		shape: 'square',
		max: MAX_SQUARES
	},
	DiamondsGridMini: {
		animated: true,
		shape: 'rectangle',
		max: 2
	}
}
export const VISUAL_OPTION_SHAPES = {
	DayBoxes: [
		'cube',
		'piramid',
		'capsule',
		'diamond',
		'emerald',
		'heart'
	],
	DiamondsRing: [
		'flower',
		'diamond',
		'heart'
	],
	BigSingleShape: [
		'flower',
		'diamond',
		'heart',
		'rose',
		'skull',
	]
}

export const phonePosition = [0,0,0]//2,11.5,9]

export function VisHasOptionShape(visType) {
	return VISUAL_OPTION_SHAPES.hasOwnProperty(visType)
}
export function VisHasOptionShapeAndCurShape(visType,shapeType) {
	let return_val = false
	let has_vis_ops = VISUAL_OPTION_SHAPES.hasOwnProperty(visType) 
	if(!has_vis_ops) { return true }
	if( 
		shapeType
		&&
		has_vis_ops
		&&
		VISUAL_OPTION_SHAPES[visType].indexOf(shapeType) != -1
	) {
		return_val = true
	}
	return return_val
}
export function VisHasOptionSpeed(visType) {
	return (visType && VISUAL_OPTIONS[visType].animated)
}
export function VisHasVisualOptions(visualData) {
	let hasVisualOptions = false
	if(visualData.hasOwnProperty('dataTag') && DATA_OPTIONS[visualData.dataType].hasOwnProperty('visual_options')) {
		hasVisualOptions = true
	}
	return hasVisualOptions
}
export function VisHasGridOptions(visualData) {
	let hasGridOptions = false
	if(visualData.hasOwnProperty('dataType') && DATA_OPTIONS[visualData.dataType].hasOwnProperty('grid_options')) {
		hasGridOptions = true
	}
	return hasGridOptions
}

