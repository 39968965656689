import { OrbitControls, useGLTF } from '@react-three/drei'
import * as THREE from 'three'
import { Debug } from '@react-three/rapier'
import { GUI } from 'three/examples/jsm/libs/lil-gui.module.min.js';
import { useState, useMemo, useRef, useEffect  } from 'react'
import { useFrame, Canvas, useThree } from '@react-three/fiber'
import ColorScheme from 'color-scheme'

import {GridHelper} from './helpers/GridHelpers'

const lineGraphMaterial = new THREE.MeshStandardMaterial({ 
	color: 0x00ff00,
	flatShading: true,
	transparent: true,
	// wireframe: true.
	opacity: .95,
	metalness: 0.4,
	roughness: 0.9,
})

function raiseVertices(geom, points, amount) {
	// console.log(points.length)
	for(let vI of points) {
		// console.log(vI)
	    geom.array[vI + 1] += amount
	}
}
const Days2Vertices = {
	'top': {
		0: 3,
		897: -3,
		1794: -6,
		1797: -6
	},
	'bot': {
		300: 3,
		1197: -3,
		1800: 6,
		1803: 6
	}
}

const ExceptionDays = {
	0: {
		top: [2400,2403],
		bot: [2406,2409]
	},
	99: {
		top: [2412,2415],
		bot: [2418,2421]
	}
}

const lgWidth = .5

function BuildLineGraph({geom, mat, grid, lineIndex}) {

	const cubeMeshPos = geom.getAttribute( 'position' );

	const gridData = GridHelper(grid, true)
	grid = gridData.gridArray;
	const valMax = gridData.max
	const valMin = gridData.min


	const dif = valMax - valMin

	// console.log(valMin, valMax, 'dif:', dif)//, 'LineGraph: grid', grid)

	const maxGrowth = 10
	const valMultiplier = maxGrowth / dif;

	const maxY = valMax * valMultiplier

	const numDays = grid.length;

	const fillTop = false

	for(let i=0; i < numDays; i++) {

		let shiftAmount = (grid[i] - valMin) * valMultiplier;

		// Top ones first
		let topVertices2Shift = []
		for(let vKey in Days2Vertices.top) {
			let vInc = parseInt( Days2Vertices.top[vKey] )
			let vertexIndex = parseInt(vKey) + ( i * vInc);
			topVertices2Shift.push(vertexIndex)
		}
		// Add special enders
		if(ExceptionDays.hasOwnProperty(i)) {
			topVertices2Shift = topVertices2Shift.concat(ExceptionDays[i].top);
		}
		let topY = (fillTop) ? maxY : shiftAmount
		// Set shiftamount for the top
		raiseVertices(cubeMeshPos, topVertices2Shift, topY);

		// Bottom ones
		let botVertices2Shift = []
		for(let vKey in Days2Vertices.bot) {
			let vInc = parseInt( Days2Vertices.bot[vKey] )
			let vertexIndex = parseInt(vKey) + ( i * vInc);
			botVertices2Shift.push(vertexIndex)
		}
		// Add special enders
		if(ExceptionDays.hasOwnProperty(i)) {
			botVertices2Shift = botVertices2Shift.concat(ExceptionDays[i].bot);
		}
		// Set shiftamount
		raiseVertices(cubeMeshPos, botVertices2Shift, shiftAmount);
	}

	let posX = -1 * (lineIndex * lgWidth)

	return(	
		<>
			<mesh
				position={[posX, 0, 0]}
		        name="LineGraph"
			    geometry={geom}
			    material={mat}
	        ></mesh>	        
        </>
    )
}

export default function LineGraph({grid, playBackSpeed, displayColor}) {


	const boxGeom = new THREE.BoxGeometry(lgWidth, 1, 30, 1,1,99)

	// Single line or multiple lines to place ?
	let gridKeys = Object.keys(grid);
	let firstKey = gridKeys[0]
	let singleGrid = !isNaN(firstKey)
	// console.log('gridKeys',firstKey, singleGrid,  gridKeys)

	// Get different colors for each line
	let colorHex = displayColor.getHexString()
	if(!singleGrid) {
		var s = new ColorScheme;
		s.from_hex( colorHex )
		var colorOptions = s.scheme('triade')
		.distance(.1)
		.colors();	
	} else {
		var colorOptions = []	
	}
	 // Add initial color as first
	colorOptions.unshift(colorHex)

	let grids2Place = [];
	if(singleGrid) {
		grids2Place = [grid]
	} else {
		for(let subGrid in grid) {
			grids2Place.push(grid[subGrid])
		}
	}

	const lineGraphs = []
	
	let gridI = 0

	for(let grid2PlaceKey in grids2Place ) {
		let grid2Place = grids2Place[grid2PlaceKey]
		let lGeom = boxGeom.clone()
		const lMat = lineGraphMaterial.clone()
		let colorIndex = gridI * 4;
		lMat.color = new THREE.Color( '#' + colorOptions[colorIndex] )
		
		const blg = <BuildLineGraph 
			key={`lineGrid-${grid2PlaceKey}`} 
			grid={grid2Place} 
			geom={lGeom} 
			mat={lMat}
			lineIndex={gridI}
		/>
		lineGraphs.push(blg);
		gridI++;
	}
	// return false;


	return (
		<>
			{lineGraphs}
		</>
	)

}