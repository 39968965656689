import { useGLTF, MeshRefractionMaterial, Text3D } from '@react-three/drei'
import * as THREE from 'three'
import { useState, useRef, useEffect  } from 'react'
import { useFrame } from '@react-three/fiber'

import {TikTokLogo, UXIconEdit, UXIconInfo, UXIconDelete, UXIconCreator} from './shapes/Phone'
import {invertColor} from './helpers/ColorHelpers'
import ApiDataLoader from './ApiDataLoader'

import {DayBoxesGrid, setShapeInitYs} from './DayBoxesGrid'
import CreatorHeightmap from './CreatorHeightmap'
import DiamondsRing from './DiamondsRing'
import LineGraph from './LineGraph'
import DayHeightMap from './DayHeightMap'
import DiamondsGrid from './DiamondsGrid'
import BigSingleShape from './BigSingleShape'

import LoadingIcon from './helpers/LoadingIcon'

import { PhoneDayCounters, ActiveVisuals, EditorState, EDTR_ACTIVE_DATA } from  './helpers/VisualsDataExchange'

import {textMaterialDark} from './materials/ProjectMaterials'
// User Logger
import { UserLog } from  './UserActivityLogger'

THREE.ColorManagement.legacyMode = false

// console.log(MeshRefractionMaterial)

const obstacleMaterial = new THREE.MeshStandardMaterial({ 
	color: 0x00ff00,
	flatShading: true,
	transparent: true,
	opacity: .9,
	metalness: 0.4,
	roughness: 0.9,
})

/*
let dataSettings = {mode, tag}
let visualData = {
	shape: getRandShape(),
	color: getRandColor(),
	speed: 15
*/
export default function DataVisualInstance({controller, visualData, index, ref2Set}) {


	// Data Input: tag
	const [curVisualData, setCurVisualData] = useState(visualData)
	const [mode2Load, setMode2Load] = useState( visualData.dataType )
	const [tag2Load, setTag2Load] = useState( visualData.dataTag )

	const [cats2Load, setCats2Load] = useState( '*' )
	const [editI, setEditI] = useState(0)
	
	useEffect(() => {
		console.log('DataVisualInstance:useEffect - visualData has changed')
		setCurVisualData(visualData)
	}, [visualData])
	
	// Data input: Grid
	const [grid, setGrid] = useState(false)
	const [loadingMode, setLoadingMode] = useState(true)

	const dataLoaded = (props) => {
		setLoadingMode(false)
		setGrid(props)
	}

	// console.log('_RNDRD_ DataVisualInstance!', visualData, grid)


	// Data Display: Shape to load
	let initShape = (!curVisualData.shapeType) ? 'cube' : curVisualData.shapeType ;
	const [shapeType, setShapeType]	 = useState( initShape )

	let	modelPath = "/models/dayshapes/diamonds-pre-stretched-bottomed.gltf"; // dayshapes.gltf
	useGLTF.preload(modelPath);
	
	function resetShapeType(shape) {
		setShapeType(shape);
	}

    // Data Display: Color
	const [displayColor, setDisplayColor] = useState( curVisualData.displayColor )

	function updateVidColor(color) {
   		setDisplayColor(color)
	}
    const DayBoxMaterial = obstacleMaterial.clone();
    DayBoxMaterial.color = curVisualData.displayColor;

	// Data Display: Speed
	const [playBackSpeed, setPlayBackSpeed]	 = useState( curVisualData.hasOwnProperty('displaySpeed') ? curVisualData.displaySpeed : 15 )

	function calculateNewSpeed(newSpeed) {
		const maxSpeed  = 20;
		let speedOffset = newSpeed%maxSpeed;
		let revSpeed    = maxSpeed - speedOffset;
		revSpeed        = (speedOffset == 0) ? 1 : revSpeed + 1;
		// console.log('setPlayBackSpeed',  revSpeed)
		setPlayBackSpeed( revSpeed );
	}
	// Prep an object w nessecaru data for the controller
	const getCallBackObj = () => {
		const visData = {...curVisualData}
		// console.log('- - - triggerInfoMode - - - ', visualIndex)
		if(grid && !visData.hasOwnProperty('grid')) {
			visData.grid = grid	
		}
		return {
    		index: index,
    		visualData: visData
		}
	}

	const triggerInfoMode = () => {
		controller({}, getCallBackObj(), updatePhoneSettings, 'info')
	}

    // Updater
    const props2Update = [
    		'dataType', 
    		'dataTag', 
    		'displayColor', 
    		'displaySpeed', 
    		'visualID', 
    		'visType', 
    		'shapeType'
	]
    const updatePhoneSettings = (props) => {
    	console.log('DataVisualInstance:updatePhoneSettings!', props.edit, curVisualData )
		// setCurVisualData(props)
    	if(props.hasOwnProperty('edit')) {
    		let editedData = props.edit
    		let triggerInfo = true
    		if(editedData.hasOwnProperty('displaySpeed')) {
    			calculateNewSpeed( parseInt( editedData.displaySpeed ) )
    		}
    		// Categories selected?
    		if(
    			editedData.visType.indexOf('DiamondsGrid') != -1
    			&&
    			editedData.hasOwnProperty('activeCats')
			) {
	    		setCurVisualData(editedData)
	    	}
	    	// Changed to categories ? (dataType was overrwitten somewhere else already, hacky fix incoming :3 )
	    	if(
	    		editedData.shapeType
	    		&&
	    		editedData.shapeType.indexOf('DiamondsGrid') != -1
	    		&&
	    		(
	    			curVisualData.shapeType.indexOf('DiamondsGrid') == -1
	    			||
	    			(
	    				curVisualData.grid.hasOwnProperty('followers')
	    				|| 
	    				curVisualData.grid.hasOwnProperty('videos')
	    			)
	    		)
	    	) {
	    		curVisualData.dataType = 'reset4Cats'
	    	}

    		for(let prop of props2Update) {
				let editHasProp    = editedData.hasOwnProperty(prop)
				let currentHasProp = curVisualData.hasOwnProperty(prop)
    			let propChanged = false
    			if(editHasProp && currentHasProp) {
    				// console.log(curVisualData[prop], editedData[prop], 'same?', (editedData[prop] == curVisualData[prop]))
    			}
    			// console.log(prop, 'edit', editHasProp, 'cur', currentHasProp)

    			if(
    				editedData.hasOwnProperty(prop) 
    				&& 
    				(
	    				(curVisualData.hasOwnProperty(prop) && curVisualData[prop] != editedData[prop])
	    				||
	    				(!curVisualData.hasOwnProperty(prop))
	    			)
				) {
					// console.log('we gonna update', prop)
    				switch(prop) {
	    				case 'dataType':
	    				case 'dataTag':
	    					// Reload the entire ting anyway
	    					setGrid(false)
				    		setCurVisualData(editedData)
				    		triggerInfo = false
	    				  break;
	    				case 'shapeType':
				    		resetShapeType(editedData.shapeType); 
	    				  break;
	    				case 'displayColor':
				    		updateVidColor(editedData.displayColor)
	    				  break;
    				}
				}
    		}
    		UserLog({visualEdited:editedData})
    		setEditI( editI + 1 )
    	}
    }

	// Edit Button settings
	const btnBasicColor  = curVisualData.displayColor// new THREE.Color( 0x4d4d4d);
	const matUXBtn       = new THREE.MeshBasicMaterial({color: 0x777777})
	// const matUXBtnInfo   = new THREE.MeshBasicMaterial()
	// matUXBtn.color       = btnBasicColor;
	// matUXBtnInfo.color   = btnBasicColor

    // Caller
	function VisualControl({index, controller, visualData}) {
		// console.log('get VisualControl BTNS', visualData)
		const boxGeometry = new THREE.BoxGeometry(1.4,.1,1.4)
		
		// Add grid to visualData
		visualData.grid = grid

		const phoneData = {
			index: index,
			visualID: visualData.visualID,
			visualData: visualData,
			grid: grid
		}
		if(visualData.dataType.indexOf('categories')!=-1) {
			phoneData.activeCats = visualData.activeCats
		}

		const DataVisBtnClick = function(btnMode) {
			UserLog({visualEditBtnClick:btnMode})
			// console.log('DataVisBtnClick!', btnMode, phoneData )
			controller(event, phoneData, updatePhoneSettings, btnMode)
		}

		triggerInfoMode()

		// Get button icon geometries
		const editIconGeom = UXIconEdit()
		const infoIconGeom = UXIconInfo()
		const delIconGeom  = UXIconDelete()

		let btnsPosY = index * 4.5
		const btnsScale = .7
		return <group name="edit_btns" position={[5.7,.7,10]} scale={[btnsScale,btnsScale,btnsScale]}>
				<mesh
					position={[0,.5,-.8]}
					geometry={boxGeometry}
					material={matUXBtn}
					name="visualBtnEdit"
		        	onClick={(event) => {
			        		DataVisBtnClick('edit')
				        	event.stopPropagation();
			        	}
		        	}
					>
						<mesh
							geometry={editIconGeom}
							scale={[.8,.8,.8]}
							material={textMaterialDark}
							position={[0,-.05,-.15]}
						>				
						</mesh>
				</mesh>
				<mesh
					position={[0,.5,-2.25]}
					geometry={boxGeometry}
					material={matUXBtn}
					name="visualBtnInfo"
		        	onClick={(event) => {
			        		DataVisBtnClick('info')
				        	event.stopPropagation();
			        	}
		        	}
					>
						<mesh
							geometry={infoIconGeom}
							scale={[.8,.8,.8]}
							material={textMaterialDark}
							position={[0,-.05,-.02]}
						>				
						</mesh>
				</mesh>
				<mesh
					position={[0,.5,-3.7]}
					geometry={boxGeometry}
					material={matUXBtn}
					name="visualBtnDelete"
		        	onClick={(event) => {
			        		DataVisBtnClick('delete')
				        	event.stopPropagation();
			        	}
		        	}
					>
						<mesh
							geometry={delIconGeom}
							position={[0,.1,-.02]}
							scale={[.8,.8,.8]}
							material={textMaterialDark}
						>				
						</mesh>
				</mesh>
			</group>
	}

	let loadedMeshGeom;
	const { nodes } = useGLTF(modelPath);
	if(nodes.hasOwnProperty(curVisualData.shapeType)) {
		loadedMeshGeom = nodes[curVisualData.shapeType].geometry
		setShapeInitYs(curVisualData.shapeType, loadedMeshGeom)
	}

	let tagIcon = (curVisualData.dataType.indexOf('creator')!= -1) ? 'creator' : 'hashtag'
	if(curVisualData.dataType.indexOf('categories')!=-1) { tagIcon = 'categories' }

	let diamondGridGeom;
	let gridSize
	let gridScaleY

	if(curVisualData.hasOwnProperty('visType') && curVisualData.visType.indexOf('DiamondsGrid')!=-1) {
		// console.log('is categories grid now')
		diamondGridGeom = nodes.emerald_simple.geometry
		gridSize = (curVisualData.shapeType == 'DiamondsGridMini') ? 'mini' : 'full'
		gridScaleY = ('mini' == gridSize) ? .375 : .235
		if(!curVisualData.hasOwnProperty('activeCats')) {
			curVisualData.activeCats = '*'
		}
	}

	const CTRL_BTNS = <VisualControl index={index} controller={controller} visualData={curVisualData} />


	const setDataVisualRef = (element) => {
		ref2Set.current[index] = element
	}

	// Make each Visual load its own data
	return (
		<>
			<ApiDataLoader context={'r3f'} mode={curVisualData.dataType} loadVal={curVisualData.dataTag} dataLoaded={dataLoaded} />
			<group name="visualWrap" rotation={[Math.PI * .5, Math.PI, 0]}>
				{grid && !loadingMode && 
					<>
					{'DayBoxes' == curVisualData.visType &&
						<group 
							name="vis_mover"
					        ref={ (element) => setDataVisualRef(element) }
					        userData={{visType:'DayBoxes'}}
						>
							<group name="vis_scaler"> 
								<group 
									name="normalizer" 
									position={[-4.65,1,.6]}
									scale={[1.032,1.035,1.035]}
								>
									<DayBoxesGrid 
										grid={grid} 
										boxMat={DayBoxMaterial} 
										shapeType={curVisualData.shapeType} 
										playBackSpeed={playBackSpeed}
										meshGeom={loadedMeshGeom} 
										visualID={curVisualData.visualID}
									/>
								</group>
							</group>
							{CTRL_BTNS}
						</group>
					}
					{'DayHeightMap' == curVisualData.visType &&
						<group 
							name="vis_mover"
					        ref={ (element) => setDataVisualRef(element) }
					        userData={{visType:'DayHeightMap'}}
						>
							<group name="vis_scaler"> 
								<group 
									name="normalizer" 
									rotation-y={Math.PI}  
									position={[0,1.01,5.25]}
									scale={[.87,.87,.865]}
								>
									<DayHeightMap 
										grid={grid} 
										displayColor={curVisualData.displayColor}
										playBackSpeed={(playBackSpeed * 4)}
										visualID={curVisualData.visualID}
									/>
								</group>
							</group>
							{CTRL_BTNS}
						</group>
					}
					{'CreatorHeightmap' == curVisualData.visType &&
						<group 
							name="vis_mover"
					        ref={ (element) => setDataVisualRef(element) }
					        userData={{visType:'CreatorHeightmap'}}
						>
							<group name="vis_scaler"> 
								<group 
									name="normalizer" 
									rotation-y={Math.PI}  
									position={[0,1.006,5.25]}
									scale={[.104,.05,.104]}
								>
								<CreatorHeightmap 
									grid={grid} 
									displayColor={curVisualData.displayColor}
									playBackSpeed={(playBackSpeed * 1)}
									visualID={curVisualData.visualID}
								/>
								</group>
							</group>
							{CTRL_BTNS}
						</group>
					}
					{'LineGraph' == curVisualData.visType &&
						<>
						<group name="LineGraphWrapper" 
							rotation-x={Math.PI * .5} 
							position={[7.8,7.5,2.6]} 
							scale={[6.6,1.5,.5]} 
						>
							<LineGraph 
								grid={grid} 
								displayColor={curVisualData.displayColor}
								playBackSpeed={(playBackSpeed * 2)}
								visualID={curVisualData.visualID}
							/>
						</group>
						{CTRL_BTNS}
						</>
					}
					{'DiamondsGrid' == curVisualData.visType &&
						<group 
							name="vis_mover"
					        ref={ (element) => setDataVisualRef(element) }
					        userData={{visType: curVisualData.shapeType }}
						>
							<group name="vis_scaler">
								<group 
									name="normalizer" 
									rotation={[Math.PI * .5, 0, Math.PI]} 
									position={[4.95, 1, 10.36]} 
									scale={[.375,gridScaleY,.375]} 
								>
									<DiamondsGrid 
										grid={grid} 
										cats2Show={curVisualData.activeCats}
										meshGeom={diamondGridGeom}
										displayColor={curVisualData.displayColor}
										playBackSpeed={(playBackSpeed)}
										gridSize={gridSize}
										visualID={curVisualData.visualID}
									/>
								</group>
							</group>
							{CTRL_BTNS}
						</group>
					}
					{'DiamondsRing' == curVisualData.visType &&
						<group 
							name="vis_mover"
					        ref={ (element) => setDataVisualRef(element) }
					        userData={{visType: 'DiamondsRing' }}
						>
							<group 
								name="DiamondsRingWrapper" 
								rotation-x={Math.PI * .5} 
								position={[0,-7.3,-0]} 
								scale={[1,.97,1]} 
							>
								<DiamondsRing 
									grid={grid} 
									displayColor={curVisualData.displayColor}
									shapeType={curVisualData.shapeType} 
									visualID={curVisualData.visualID}
								/>
							</group>
							{CTRL_BTNS}
						</group>
					}
					{'BigSingleShape' == curVisualData.visType &&
						<group 
							name="vis_mover"
					        ref={ (element) => setDataVisualRef(element) }
					        userData={{visType: 'BigSingleShape' }}
						>
							<group name="vis_scaler">
								<group 
									name="normalizer" 
									rotation={[Math.PI * .5, 0, 0]} 
									position={[0,-6.6,-2]} 
									scale={[1,1,1]} 
								>
									<BigSingleShape 
										grid={grid} 
										displayColor={curVisualData.displayColor} 
										shapeType={curVisualData.shapeType} 
										playBackSpeed={playBackSpeed}
										visualID={curVisualData.visualID}
									/>
								</group>
							</group>
							{CTRL_BTNS}
						</group>
					}					
					</>
				}
				{loadingMode &&
					<LoadingIcon />
				}
				
			</group>
		</>
	)
}