const defaultTagOptions = {
	'hashtags': [
		'pov',
		'humor',
		'funny',
		'nederland',
		'weer',		
	],
	'creators': [
		'meesterjesper',
		'spicy_karate',
		'newbee12321',
		'fatoemakers',
		'mr.mask.live2.0'		
	]
}

export default defaultTagOptions;