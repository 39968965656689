export const PhoneDayCounters = {}
export const ActiveVisuals = {}
export const EditorState = {}

export let EDTR_ACTIVE_DATA = {}
export function SetEdtrActiveData(data) {
	EDTR_ACTIVE_DATA = data
}

export function getMyCounters() {
	console.log('PhoneDayCounters?', PhoneDayCounters)
}

const mob_break_point = 900
export const RESPONSIVE_MODE = (window.innerWidth < mob_break_point)
