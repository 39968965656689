import * as THREE from 'three'
import { useState, useRef  } from 'react'
import { useFrame } from '@react-three/fiber'

import {TikTokLogo} from './../shapes/Phone'


export default function LoadingIcon(props) {

	return <TikTokLogo />
}