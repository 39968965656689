import { OrbitControls, useTexture, Text3D, CameraControls, Environment, PresentationControls } from '@react-three/drei'
import { Perf } from 'r3f-perf'
import * as THREE from 'three'
import { Debug } from '@react-three/rapier'
import { GUI } from 'three/examples/jsm/libs/lil-gui.module.min.js';
import { useState, useMemo, useRef, useEffect  } from 'react'
import { useFrame, Canvas, useThree } from '@react-three/fiber'
// Log the Drag
import { createUseGesture, dragAction } from '@use-gesture/react'
const useGesture = createUseGesture([dragAction])


// console.log(THREE)
const { DEG2RAD } = THREE.MathUtils

// PROJECT MODULES
// Scene elements
import Lights from './scene-elements/Lights'
import Ground from './scene-elements/Ground'
import CamControlled from './scene-elements/CamControlled'
import CameraAnimator from './scene-elements/CameraAnimator'
// Components
import ApiDataLoader from './ApiDataLoader'
import PhoneFront from './PhoneFront'
import DataVisualInstance from './DataVisualInstance'
import {getGridBoxes, LayoutVisuals} from './Layouter'
// Shapes
import { PhoneBackBase } from './shapes/Phone'
// Helpers
import DelUI from './helpers/DelUI'
import {GetRandColor} from './helpers/ColorHelpers'
import {PhoneDayCounters, getMyCounters, ActiveVisuals, EditorState, EDTR_ACTIVE_DATA, RESPONSIVE_MODE} from './helpers/VisualsDataExchange'
// Constants
import { VisHasOptionSpeed } from  './constants/DataVisSettings'
// User Logger
import { GetUserLog, UserLog } from  './UserActivityLogger'
import { UAC_Cube } from './UserActShape'

/* Post processing 
// npm install @react-three/postprocessing
// npm uninstall @react-three/postprocessing
import { EffectComposer, SSAO } from "@react-three/postprocessing";
import { BlendFunction } from "postprocessing";

function Effects() {
	return (
		<EffectComposer>
			<SSAO
			blendFunction={BlendFunction.MULTIPLY}
			samples={30}
			radius={15}
			intensity={30}
			/>
		</EffectComposer>
	);
}
<Effects />
*/


THREE.ColorManagement.legacyMode = true

let RE_BUILD_MODE = false
let BUILD_INDEX = 0


export default function PhoneTotal(props) {

	document.body.classList.add('phone')

	const [visualIndex, setVisualIndex] = useState( -1 )
	const [visualData, setVisualData] = useState({})
	const [controlI, setControlI] = useState(0)

	const [visuals2Add, setVisuals2Add] = useState({})

	const [frontDisplayMode, setFrontDisplayMode] = useState('start')

	const [layoutIndex, setLayoutIndex] = useState(0)


	console.log('PhoneTotal:frontDisplayMode', frontDisplayMode)

	const uiCanvas  = useRef()
	const phoneWrap = useRef()
	const camControl = useRef()

	const visualsPlaced = useRef([]);
	
	const changePhoneCallback = useRef(null);

	/*      _                  __              ______               __  
	 _   __(_)______  ______ _/ /  _________ _/ / / /_  ____ ______/ /__
	| | / / / ___/ / / / __ `/ /  / ___/ __ `/ / / __ \/ __ `/ ___/ //_/
	| |/ / (__  ) /_/ / /_/ / /  / /__/ /_/ / / / /_/ / /_/ / /__/ ,<   
	|___/_/____/\__,_/\__,_/_/   \___/\__,_/_/_/_.___/\__,_/\___/_/|_*/

	const VisualTotalCaller = (event, phoneData, clicker, mode) => {

		console.log('VisualTotalCaller', phoneData.index, mode)
		const visIndex = parseInt(phoneData.index)

		changePhoneCallback.current = clicker;
		let switchFrontMode = true
		switch(mode) {
			case 'edit':
				setVisualIndex( visIndex )
				setVisualData( phoneData.visualData )
				// console.log('edit: visualIndex ', visualIndex, phoneData.index, controlI)
			  break
			case 'info':
				closeEditor()
				setVisualIndex( visIndex )
				setVisualData( phoneData.visualData )

				// Auto info called after the placing of a visual ? THEN t				
				if(Object.keys(event).length == 0) {
					console.log('»»»»»»» info called! ('+visIndex+') w empty', event, RE_BUILD_MODE)

					if(RESPONSIVE_MODE) { 
						mode = 'start'
						// switchFrontMode = false 
					}
					
					if(RE_BUILD_MODE) {
						CheckReady2Layout(visIndex)
					} else {
						TriggerLayout()
					}
					// only trigger layout after the last auto info caller
					// if(RE_BUILD_MODE && )
				}
				// 		controller({}, infoCallBackObj, updatePhoneSettings, 'info')
			  break
			case 'delete':
				setVisualIndex( visIndex )
				switchFrontMode = false
				if(confirm('Are you sure?')) {
					switchFrontMode = true
					mode = 'start'
					deleteVisual(visIndex)
				}
			  break
			case 'layoutReady':
				switchFrontMode = false
				let newlI = layoutIndex + 1
				setLayoutIndex( newlI )			
				console.log('layoutReady: layoutIndex', layoutIndex, newlI)
			  break
		}
		if(switchFrontMode) {
			setFrontDisplayMode(mode)
		}
	}
	// Delete a visual from the ting
	const deleteVisual = (visualIndex) => {
		if(ActiveVisuals.hasOwnProperty(visualIndex))  {
			delete  ActiveVisuals[visualIndex]
			delete  visuals2Add[visualIndex]
			
			UserLog({DeletedVisual:visualIndex})

			setControlI( controlI + 1 )
		}
	}

	/*  ____                 __                ______               __  
	   / __/________  ____  / /_   _________ _/ / / /_  ____ ______/ /__
	  / /_/ ___/ __ \/ __ \/ __/  / ___/ __ `/ / / __ \/ __ `/ ___/ //_/
	 / __/ /  / /_/ / / / / /_   / /__/ /_/ / / / /_/ / /_/ / /__/ ,<   
	/_/ /_/   \____/_/ /_/\__/   \___/\__,_/_/_/_.___/\__,_/\___/_/|*/
	// add, edit, info? visualindex, data

	const phoneFrontTotalCaller = (frontMode, visualIndex, visualData) => {
		console.log('phoneFrontTotalCaller!', frontMode, visualIndex, visualData)
		switch(frontMode) {
			case 'add':
				let visualKeys = Object.keys(ActiveVisuals)
				let newVisualKey = visualKeys.length
				if(newVisualKey > 0) {
					let curMaxKey = Math.max(...visualKeys)
					newVisualKey = curMaxKey + 1
				}
				visuals2Add[newVisualKey] = visualData
				setVisuals2Add(visuals2Add)
				// Add to global ActiveVisuals
				ActiveVisuals[newVisualKey] = visualData
				// Set editorstate for so can call info screen after adding later on
				EditorState.editedIndex = -1
				EditorState.newIndex = newVisualKey
				// set to start modus for now
				setFrontDisplayMode('start')
				setVisualIndex(-1)
				// Add to user log
				UserLog({AddedVisual:visualData})
				// Force ReMemo - phonefront (hacky...)
				setControlI( controlI + 1 )
			  break;
			case 'edit':
				console.log('====edited visualIndex', visualIndex, visualData, EDTR_ACTIVE_DATA)
				// Set editorstate for so can call info screen after editing later on
				EditorState.editedIndex = visualIndex
				EditorState.newIndex = -1
				// Call the update function in the relevant visual
				changePhoneCallback.current({'edit': EDTR_ACTIVE_DATA})
				// Update global activevisuals object
				ActiveVisuals[visualIndex] = EDTR_ACTIVE_DATA
				UserLog({EditedVisual:EDTR_ACTIVE_DATA})

			  break;
			case 'set2add':
				setFrontDisplayMode('add')
			  break;
			case 'profile':
				let newDisplayMode = frontMode
				if('profile' == frontDisplayMode) {
					newDisplayMode = 'start'
				}
				setFrontDisplayMode(newDisplayMode)
				// CamPivot('darkside')
			  break;
			case 'start':
				setFrontDisplayMode(frontMode)
			  break;
		}
	}
	

	const closeEditor = () => {
		// setVisualIndex(-1)
	}

	/*                                    __                          __      
	   _____________  ____  ___     ___  / /__  ____ ___  ___  ____  / /______
	  / ___/ ___/ _ \/ __ \/ _ \   / _ \/ / _ \/ __ `__ \/ _ \/ __ \/ __/ ___/
	 (__  ) /__/  __/ / / /  __/  /  __/ /  __/ / / / / /  __/ / / / /_(__  ) 
	/____/\___/\___/_/ /_/\___/   \___/_/\___/_/ /_/ /_/\___/_/ /_/\__/___*/	
	// Memo the Lights

	const sceneLights = useMemo(() => {
		return <Lights />
	}, [BUILD_INDEX])

	// Memo the Ground
	const sceneGround = useMemo(() => {
		return <Ground />
	}, [])

	// Memo the Phone Background
	const PhoneBack = useMemo(() => {
		return <PhoneBackBase  setRef={phoneWrap}  />
	}, [])

	// Memo the Environment
	const sceneEnvironment = useMemo(() => {
		return <Environment files="/imgs/wasteland_clouds_puresky_1k-V02.hdr" background />
	}, [])

	const CanvasDoubleClick = () => {
		/*
		console.log('------ CanvasDoubleClickd --- ', ActiveVisuals, U3_objects.camera)
		console.log( 'camera position:', U3_objects.camera.position)
		console.log( 'camera quaternion:', U3_objects.camera.quaternion)
		*/
		GetUserLog()
	}
	let U3_objects
	const camPivotFunction = useRef(null)
	// After initialization of CamAnimator, it does a callback to this, so the control function can be set
	const CamAnimationControl = (CamControlFunction, scene_objs) => {
		U3_objects = scene_objs
		camPivotFunction.current = CamControlFunction	
	}
	
	useEffect(() => {
		const side = ('profile' == frontDisplayMode) ? 'darkside' : 'brightside'
		if(camPivotFunction.current) { camPivotFunction.current({moveTo: side}) }
	}, [frontDisplayMode])
	

	/* Place & memo the
	 _   __(_)______  ______ _/ /____
	| | / / / ___/ / / / __ `/ / ___/
	| |/ / (__  ) /_/ / /_/ / (__  ) 
	|___/_/____/\__,_/\__,_/_/___*/	

	let xIncr = 12;
	let yIncr = 0;//-1.5;

	const VisualsGroup = useMemo(() => {
		RE_BUILD_MODE = true
		console.log('update VisualsGroup!', visuals2Add)
		let AddedPhones = [];
		for(let phone_i in visuals2Add) {
			let thisVisualData = visuals2Add[phone_i];
			// console.log(thisVisualData)
			let ph_x       = 0 //phone_i * xIncr;
			let ph_y       = 0 //phone_i * yIncr;
			let mode       = thisVisualData.dataType;
			let visual     = thisVisualData.visType;
			let tag        = thisVisualData.dataTag;

			let visualID = `visual--${visual}-${mode}-${tag}-${phone_i}`

			// Add ID to visualdata
			thisVisualData.visualID = visualID
			// Needs a global daycounter ?
			if(VisHasOptionSpeed(thisVisualData.visType)) { PhoneDayCounters[visualID] = 0 }

			AddedPhones.push(
				<group 
					name={visualID}
					position={[ph_x,ph_y,0]} 
					key={visualID}
				>
					<DataVisualInstance 
						controller={VisualTotalCaller}
						visualData={thisVisualData}
						index={phone_i}
						ref2Set={visualsPlaced}
					/>
				</group>
			)
		}

		return <>{AddedPhones}</>
	}, [controlI])

	/*  __                        __ 
	   / /___ ___  ______  __  __/ /_
	  / / __ `/ / / / __ \/ / / / __/
	 / / /_/ / /_/ / /_/ / /_/ / /_  
	/_/\__,_/\__, /\____/\__,_/\__/  
	        /___*/	

	function CheckReady2Layout(visIndex) {
		// Ready4Layout()
		if(RE_BUILD_MODE) {
			const maxVisIndex = Math.max(...Object.keys(ActiveVisuals) )
			if(visIndex == maxVisIndex) {
				RE_BUILD_MODE = false
				TriggerLayout()
			}
		}
	}
	function TriggerLayout() {
		BUILD_INDEX++
	}
	useEffect(() => {
		// console.log('_____hi_____ this is LAYOUT updatings ready', visualsPlaced)
		LayoutVisuals(visualsPlaced)
	}, [BUILD_INDEX])


	/*      _             
	 _   __(_)__ _      __
	| | / / / _ \ | /| / /
	| |/ / /  __/ |/ |/ / 
	|___/_/\___/|__/|_*/

	// Camera default postition
	const canvasCamProps = {
		position:[-1.5, 12, 12.5],
		rotation:[0,0,0]  // [0,Math.PI,0] 4 other side 
	}
	const visualPositions = {
		phoneCover: [4.5, 11.5, -5],
		userVisual: [-7, 11, 36.5]
	}

	if(RESPONSIVE_MODE) {
		canvasCamProps.position[0] = 0
		visualPositions.phoneCover[0] = 0
		visualPositions.phoneCover[1] = 13
		visualPositions.userVisual[0] = -3.4
	}

	// Presentation control settings 4 da foon
	const PRES_CONTROL_PHONE = {
		zoom: .8,
		rotation: [0, 0, 0],
		polar: [-Math.PI / 3, Math.PI / 2],
		azimuth: [-Math.PI / 1.8, Math.PI / 2.6]
	}
	// Presentation control settings 4 USER Activity Cube
	const PRES_CONTROL_UAC = {
		zoom: .8,
		rotation: [0, 0, 0],
		polar: [-Math.PI, Math.PI],
		azimuth: [-Math.PI, Math.PI]
	}
	const presentatlon_global = (RESPONSIVE_MODE) ? true : false
	// Grid box helper
	const GridBoxes = getGridBoxes()
	
	const dragBinder = useGesture({
		onDragEnd: () => { UserLog({phoneCover:'dragged'}) }
	})	

	/*
	const zeroGeom = new THREE.BoxGeometry(1,1,1)
	const zeroMat = new THREE.MeshBasicMaterial({color: 0xff0000})
	const zeroBox = <mesh geometry={zeroGeom} material={zeroMat} />
	*/
	// camera={canvasCamProps}
	return (<>
		<DelUI />
	    <Canvas ref={uiCanvas} onDoubleClick={CanvasDoubleClick} camera={canvasCamProps} >
	    	<CameraAnimator callback={CamAnimationControl} />
	    	{/*<OrbitControls />*/}
	    	{/*{zeroBox}*/}
    		{/*<CamControlled useRef={camControl} />*/}
	    	{/*<Perf position="bottom-right" />*/}
			<group name="phoneCoverPositioner" position={visualPositions.phoneCover} {...dragBinder()} >
				<PresentationControls 
					global={presentatlon_global}
					zoom={PRES_CONTROL_PHONE.zoom} 
					rotation={PRES_CONTROL_PHONE.rotation} 
					polar={PRES_CONTROL_PHONE.polar} 
					azimuth={PRES_CONTROL_PHONE.azimuth}
				>
					{VisualsGroup}
					{PhoneBack}
				</PresentationControls>
				{sceneLights}
			</group>
			{'profile' == frontDisplayMode &&
				<group name="phoneCoverPositioner" position={visualPositions.userVisual} {...dragBinder()} >
					<PresentationControls 
						global={presentatlon_global}
						zoom={PRES_CONTROL_UAC.zoom} 
						rotation={PRES_CONTROL_UAC.rotation} 
						polar={PRES_CONTROL_UAC.polar} 
						azimuth={PRES_CONTROL_UAC.azimuth} 					
					>
					<UAC_Cube />
					</PresentationControls>
				</group>
			}
	        {sceneGround}
	        {/*<Background />*/}
	        {sceneEnvironment}
	        
	        <color args={['#cccccc']} attach="background" />
	    </Canvas>
	    <PhoneFront 
	    	displayMode={frontDisplayMode}
	    	visualIndex={visualIndex} 
	    	visualData={visualData}
			callBack={phoneFrontTotalCaller}
			controlI={controlI}
	    />
	</>
	)
}